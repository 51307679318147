import { Pane, Text, GlobeIcon } from "evergreen-ui";
import { theme } from "theme";

export const ProcessingLoader = ({ children, msg, ...rest }) => {
  return (
    <Pane
      zIndex={9}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={20}
      width="100%"
      height="100vh"
      maxHeight="100%"
      overflow="hidden"
      paddingBottom={200}
      background={theme.colors.black}
      {...rest}
    >
      <GlobeIcon className="blink" color={theme.colors.primary} size={90} />
      <Text color="grey">{msg}</Text>
    </Pane>
  );
};
