import axios from "axios";
export const generateToken = async () => {
  try {
    const { data } = await axios.post("/api/plaid/link");

    if (!data || data?.error) throw new Error();
    return data.link_token;
  } catch (error) {
    return false;
  }
};
