import { Pane } from "evergreen-ui";
import { theme } from "theme";
export const FooterCard = ({ children }) => {
  return (
    <Pane
      width="100%"
      textAlign="center"
      background={theme.colors.accent}
      paddingY={40}
    >
      {children}
    </Pane>
  );
};
