import { usePlaidLink } from "react-plaid-link";
import axios from "axios";
import { Button, ArrowRightIcon } from "evergreen-ui";
import { errorHandler, windowRedirect } from "utils";

export const PlaidLink = ({ agree, linkToken, loading, setLoading }) => {

  const { open, ready, error } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token) => {
      if (error) {
        return errorHandler();
      }

      try {
        setLoading(true);
        const { data } = await axios.post("/api/plaid/exchange", {
          public_token: public_token,
        });

        if (!data) {
          throw new Error();
        }
        
        windowRedirect("dashboard");
      } catch (error) {
        errorHandler();
        localStorage.removeItem("session");
        setLoading(false);
      }
    },
  });

  return linkToken ? (
    <Button
      width="fit-content"
      margin="auto"
      marginTop={12}
      padding={20}
      fontSize={15}
      appearance="primary"
      intent="success"
      iconAfter={ArrowRightIcon}
      onClick={() => open()}
      disabled={!ready || !agree}
      isLoading={loading}
    >
      Connect
    </Button>
  ) : (
    <Button
      width="fit-content"
      margin="auto"
      marginTop={12}
      padding={20}
      fontSize={15}
      appearance="primary"
      intent="success"
      iconAfter={ArrowRightIcon}
      disabled={true}
    >
      Connect
    </Button>
  );
};
