import { Pane } from "evergreen-ui";

export const HeaderBox = ({children}) => {
  return (
    <Pane
      position="sticky"
      zIndex={5}
      elevation={2}
      top={60}
      left={60}
      height={80}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={15}
      paddingLeft={15}
      background="whitesmoke"
      borderBottom={`solid grey 1px`}
      borderRadius={0}
    >
      {children}
    </Pane>
  );
};
