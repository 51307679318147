export const theme = {
  colors: {
    primary: "#52BD95",
    accent: "#7fb09d",
    red: "#D14343",
    blue: "royalblue",
    yellow: "#faf682",
    black: "#242424",
    offBlack: "#363535",
    white: "white",
    offWhite: "#fcfcfc",
    labelColor: "#5c5c5c",
    orange: "orange"
  },

  fonts: {},
  
  breakpoints: {
    rootWidth: 1000,
    maxWidth: '95%',
    mobile: "700"
  }
};
