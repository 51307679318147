import { Pane, Heading, Text } from "evergreen-ui";
import { format } from "date-fns";
import { theme } from "theme";

export const BlogBox = ({ title, children }) => {
  return (
    <Pane width={1000} maxWidth='95%' display="flex" flexDirection="column" paddingY={150}>
      <Heading fontSize={25} marginBottom={20} color={theme.colors.primary}>
        {title}
      </Heading>
      <Text fontSize={12} color="lightgrey" >
        {format(new Date(), "yyyy-MM-dd")}
      </Text>
      <Pane display="flex" flexDirection="column" gap={10}>
        {children}
      </Pane>
      <Text marginTop={50} color="white">
        - The Degrowth Team
      </Text>
    </Pane>
  );
};
