import { useContext } from "react";
import { UserContext } from "context";
import axios from "axios";
import { CloudUploadIcon as UploadIcon, Pane, Text } from "evergreen-ui";
import { ImageInput } from "components/Base";
import { errorHandler } from "utils";
import { theme } from "theme";

export const PdfUpload = ({ setFetching, setShowSignUp }) => {
  const { setUser } = useContext(UserContext);

  const uploadTransactions = async (file) => {
    try {
      if (!file) throw new Error();
      setFetching(true);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("cookie", localStorage.getItem("freemium"));

      const { data } = await axios.post(
        "/api/plaid/transactions-pdf",
        formData
      );
      if (!data) throw new Error();
      setFetching(false);

      if (data === "unautherized") {
        setShowSignUp(true);
        return;
      }

      localStorage.setItem("freemium", "supercalifragilisticexpialidocious");
      setUser(data);
    } catch (error) {
      setFetching(false);
      errorHandler("Unable to process transactions.");
    }
  };
  return (
    <>
      <Pane
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={30}
        paddingBottom={200}
      >
        <ImageInput
          id="file-upload"
          onChange={async (e) => uploadTransactions(e.target.files[0])}
        >
          <Pane
            cursor="pointer"
            width={300}
            height={300}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={30}
            padding={30}
            border={`solid 1px white`}
            borderRadius={5}
          >
            <UploadIcon color={theme.colors.primary} size={100} />
            <Text color="white" textAlign="center" lineHeight={2}>
              Upload a PDF of your transaction history for the last month
            </Text>
          </Pane>
        </ImageInput>
      </Pane>
    </>
  );
};
