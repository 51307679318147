import { Pane, Link } from "evergreen-ui";

export const Image = ({ blog, image }) => {
  return (
    <Pane marginTop={20}>
      <img
        alt={``}
        src={image?.source}
        width="100%"
        height={blog ? "auto" : 0}
        style={{ borderRadius: "10px" }}
      />
      {blog ? (
        <Pane width="100%" display="flex" justifyContent="flex-end">
          <Link
            width="fit-content"
            textAlign="right"
            fontSize={8}
            href={image.photographer_url}
            target="_blank"
          >
            Photo: {image?.photographer}
          </Link>
        </Pane>
      ) : null}
    </Pane>
  );
};
