import { Heading } from "evergreen-ui";

export const Title = ({ blog }) => {
  return blog ? (
    <Heading
      className={blog ? "slow-fade" : "none"}
      fontSize={18}
      lineHeight={1.6}
    >
      {blog.title}
    </Heading>
  ) : null;
};
