import { useContext } from "react";
import { UserContext } from "context";
import { Pane } from "evergreen-ui";
export const LandingBox = ({ children }) => {
  const { user } = useContext(UserContext)
  return !user ?
    <Pane width='100%' display="flex" justifyContent="center" minHeight="100dvh">
      <Pane
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {children}
      </Pane>
    </Pane>
  :  null
};
