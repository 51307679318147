import { BlogBox, Title, Section, Quote, Image } from ".";

export const Blog1 = () => {
  return (
    <BlogBox title="Reclaiming Ourselves">
      <Image src="./assets/degrowth_background_forest.jpeg" />
      <Section>
        The relentless pursuit of "more" permeates nearly every facet of modern
        life. From career ladders to productivity hacks, the societal narrative
        equates personal worth with constant upward mobility. We strive to
        achieve, acquire, and perform, often at the expense of our well-being
        and sense of self. However, amidst this relentless pressure, a
        transformative shift is emerging: <b>personal degrowth.</b>
      </Section>
      <Section>
        Personal degrowth isn't about embracing stagnation or laziness. It's a
        conscious choice to step off the treadmill of "more" and redefine
        success on your own terms. It's about acknowledging the pervasiveness of
        the "growth at all costs" narrative and questioning its impact on our
        lives.
      </Section>

      <Title>Deconstructing the Growth Narrative</Title>
      <Section>
        The concept of continuous growth is deeply ingrained in modern society.
        From childhood, we're bombarded with messages about achieving
        milestones, exceeding expectations, and constantly learning new skills.
        Societal structures reinforce this narrative through career
        advancements, productivity hacks, and the relentless glorification of
        "hustle culture."
      </Section>
      <Section>
        However, this relentless pursuit of external validation and achievement
        often comes at a cost. We experience burnout, anxiety, and a sense of
        disconnection from ourselves and our loved ones. We chase external
        validation, neglecting our authentic desires and priorities. In this
        context, degrowth emerges as a powerful counterpoint, inviting us to:
      </Section>
      <Quote>
        " Personal degrowth isn't about embracing stagnation or laziness. It's a
        conscious choice to step off the treadmill of "more" and redefine
        success on your own terms. "
      </Quote>
      <Section>
        <b>Reclaim our time:</b> Instead of endlessly chasing the next
        accomplishment, degrowth encourages prioritizing activities that bring
        genuine fulfillment and fostering meaningful connections. It's about
        reclaiming control over our time and energy, allowing us to engage in
        activities that nourish our souls.
      </Section>
      <Section>
        <b>Redefine success:</b> Degouth empowers us to shift the focus from
        external validation to internal fulfillment. It prompts us to question
        societal definitions of success and identify what truly matters to us.
        What are your values, passions, and aspirations beyond the pressures and
        expectations imposed by others?
      </Section>
      <Section>
        <b>Embrace imperfection:</b> In the relentless pursuit of growth, we
        often fall victim to the tyranny of "shoulds." We should be more
        productive, more successful, and constantly achieve new things. Degrowth
        allows us to embrace imperfection and acknowledge that progress isn't
        always linear. It allows for rest, reflection, and learning from
        setbacks, understanding that growth can sometimes involve letting go.
      </Section>
      <Title>Embracing Degrowth in Action</Title>
      <Section>
        <b>Simplifying your lifestyle:</b> Degrowth encourages us to declutter
        our physical and digital spaces, shedding possessions and online
        subscriptions that no longer serve us. By minimizing external stimuli,
        we create space for introspection and clarity.
      </Section>
      <Section>
        <b>Setting healthy boundaries:</b> Degrowth fosters an awareness of our
        personal limitations and the importance of prioritizing our well-being.
        It empowers us to set and uphold boundaries with work, social media, and
        other demands on our time and energy.
      </Section>
      <Section>
        <b>Prioritizing meaningful experiences:</b> Degrowth encourages us to
        prioritize experiences that bring us genuine joy and fulfillment. This
        may include reconnecting with loved ones, pursuing hobbies, or engaging
        in activities that nourish our creativity and sense of self.
      </Section>
      <Title>A Journey of Rediscovery</Title>
      <Section>
        Personal degrowth is not a passive process; it requires active
        reflection, conscious choices, and a willingness to challenge ingrained
        societal narratives. It's a journey of rediscovering oneself,
        identifying what truly matters, and prioritizing our well-being in an
        achievement-obsessed world.
      </Section>
      <Section>
        This journey may involve challenges. We might face resistance from
        societal expectations and internalized narratives about the importance
        of constant growth. However, as we embrace personal degrowth, we begin
        to cultivate a deeper sense of self-awareness, fulfillment, and
        connection. We reclaim control over our lives, prioritizing what truly
        matters and defining our own unique path to thriving.
      </Section>
      <Section>
        Ultimately, personal degrowth is not about achieving less, but about
        achieving what truly matters. It's about shifting our focus from
        external validation to internal fulfillment, prioritizing well-being,
        and embracing a more sustainable and meaningful way of living. In a
        world obsessed with "more" this conscious choice to step back, redefine
        success, and prioritize our well-being can be a powerful act of personal
        liberation.
      </Section>
    </BlogBox>
  );
};
