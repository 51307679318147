import { Pane } from "evergreen-ui";

export const PwaIcon = ({ ...rest }) => {
  return (
    <Pane display="flex" justifyContent="center" alignItems="center" {...rest}>
      <img
      alt={`${process.env.REACT_APP_BRAND} pwa`}
        src="./assets/degrowth_pwa.png"
        width={150}
        height={42}
      />
    </Pane>
  );
};
