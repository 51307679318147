import { useState } from "react";
import axios from "axios";
import {
  PrimaryInput,
  AuthLogo,
  AuthButton,
  AuthHeading,
} from "components/Base";
import { Footer } from "./Footer";
import { errorHandler, windowRedirect } from "utils";

export const Login = ({ setMode }) => {
  const [input, setInput] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  // ********************************************
  // LOGIN
  // ********************************************

  const onLogin = async () => {
    try {
      //soft validation
      if (!input.email.includes("@") || !input.email.includes(".")) {
        return errorHandler("Invalid email format.");
      }

      //loading
      setLoading(true);

      // api request
      const { data } = await axios.post("api/users/login", input);
      // handle errors
      const { error } = data;
      if (error) throw error;

      windowRedirect("dashboard");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <AuthHeading title={`Sign In`} />
      <AuthLogo />
      <PrimaryInput
        autoFocus={true}
        label="Email"
        type="email"
        value={input.email}
        onChange={(e) =>
          setInput((prev) => ({ ...prev, email: e.target.value }))
        }
      />
      <PrimaryInput
        label="Password"
        type="password"
        value={input.password}
        onChange={(e) =>
          setInput((prev) => ({ ...prev, password: e.target.value }))
        }
      />

      <AuthButton
        label="Sign In"
        loading={loading}
        onClick={async () => await onLogin()}
      />
      <Footer setMode={setMode} />
    </>
  );
};
