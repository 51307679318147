import { Pane, Link } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";

export const SocialMedia = ({ isShown = true, iconColor = 'white', ...rest}) => {
  const tiktok = "https://tiktok.com";
  const youtube = "https://youtube.com";

  return isShown ?
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={30}
      paddingBottom={20}
      {...rest}
    >
      <Link href={tiktok} target="_blank">
        <FontAwesomeIcon size="2x" color={iconColor} icon={faTiktok} />
      </Link>
      <Link href={youtube} target="_blank">
        <FontAwesomeIcon size="2x" color={iconColor} icon={faYoutube} />
      </Link>
    </Pane>
  : null
};