import { useContext } from "react";
import { Heading, Pane } from "evergreen-ui";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { theme } from "theme";
import { UserContext } from "context";
import { constants } from "constants";
const { PASSING_GRADE } = constants;
Chart.register(ArcElement);

export const DonutChart = () => {
  const { user } = useContext(UserContext);
  const score = Number(user.score);

  const colorGrade = () => {
    if (score < PASSING_GRADE) {
      return theme.colors.red;
    } else {
      return theme.colors.primary;
    }
  };

  const data = {
    labels: ["", ""],
    datasets: [
      {
        data: [score, 100 - score],
        backgroundColor: [colorGrade(), "whitesmoke"],
        hoverBackgroundColor: [theme.colors.primary, "white"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
  };

  return (
    <Pane
      cursor="pointer"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={25}
      background={theme.colors.black}
      onClick={() => window.location.reload()}
    >
      <Pane width={350} height={350} background={theme.colors.black}>
        <Pane
          position="absolute"
          width={350}
          height={350}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color="white" fontSize={40}>
            {user.score}%
          </Heading>
        </Pane>
        <Doughnut data={data} options={options} />
      </Pane>
    </Pane>
  );
};
