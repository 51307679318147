import { Link, Text } from "evergreen-ui";

export const ContactUs = () => {
  return (
    <Link href={`mailto:${process.env.REACT_APP_EMAIL}`}>
      <Text cursor="pointer" fontSize="0.8rem" color="white">
        Contact Us
      </Text>
    </Link>
  );
};
