import { Pane, TextInput } from "evergreen-ui";
import { InputLabel } from "..";

export const PrimaryInput = ({
  type,
  labelColor,
  label,
  tip,
  error,
  disabled,
  hover,
  maxLength,
  ...rest
}) => {

  return (
    <Pane width="100%" display="flex" flexDirection="column" gap={12}>
      <InputLabel
        labelColor={labelColor}
        label={label}
        hover={hover}
        tip={tip}
      />
      <TextInput
        border="solid lightgrey 1px"
        type={type}
        label=""
        disabled={disabled}
        isInvalid={error}
        width='100%'
        height={38}
        maxLength={maxLength}
        {...rest}
      />
    </Pane>
  );
};
