import {
  Heading,
  Pane,
  Paragraph,
  Button,
  GlobeIcon as MissionIcon,
  FlashIcon as SpotlightIcon,
  ArrowRightIcon,
} from "evergreen-ui";
import { scrollIntoView, windowRedirect } from "utils";
import { theme } from "theme";

export const Features = () => {
  const FeatureCard = ({ icon, title, content, btnText, onClick }) => {
    return (
      <Pane
        elevation={4}
        width={400}
        maxWidth="95%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={15}
        background="white"
        padding={30}
        borderRadius={5}
        border="solid lightgrey 1px"
      >
        <Heading fontSize={20}>{title}</Heading>
        {icon}
        <Paragraph minHeight={80} textAlign="center">
          {content}
        </Paragraph>
        <Button
          appearance="primary"
          intent="success"
          iconAfter={ArrowRightIcon}
          onClick={onClick}
          fontSize={14}
          padding={18}
        >
          {btnText}
        </Button>
      </Pane>
    );
  };

  return (
    <Pane
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      paddingTop={120}
      paddingBottom={150}
      background={theme.colors.white}
    >
      <Pane className="feature-box">
        <FeatureCard
          icon={<MissionIcon size={55} color={theme.colors.primary} />}
          title="Our Mission"
          content="We aim to empower communities to actively contribute to a future of economic equality and environmental accountability"
          btnText="Get Started"
          onClick={() => {
            const session = localStorage.getItem("session");
            if (session) return windowRedirect("dashboard");
            windowRedirect("plans");
          }}
        />
        <FeatureCard
          icon={<SpotlightIcon size={55} color={theme.colors.primary} />}
          title="Spotlight Series"
          content="Every Monday we dive into a new environmental topic, shinning light on the issue, and the roll each of us play in our collective future."
          btnText="Spotlight Series"
          onClick={() => scrollIntoView("spotlight")}
        />
      </Pane>
    </Pane>
  );
};
