import { Dialog } from "evergreen-ui";
import {
  LoadingContent,
  DialogBox,
  Title,
  Image,
  Content,
  AlternativeSelect,
} from "./components";
import { SocialMedia } from "components/Base";
import { theme } from "theme";

export const TransactionDialog = ({
  selected,
  image,
  setImage,
  blog,
  setBlog,
  showBlog,
  setShowBlog,
}) => {
  return (
    <Dialog
      title={<AlternativeSelect blog={blog} category={selected} />}
      isShown={showBlog}
      onCloseComplete={() => {
        setShowBlog(false);
        setBlog("");
        setImage("");
      }}
      hasFooter={false}
      contentContainerProps={{ height: "auto" }}
    >
      <Title blog={blog} />
      <LoadingContent blog={blog} />
      <DialogBox blog={blog}>
        <Image blog={blog} image={image} />
        <Content blog={blog} />
        <SocialMedia
          isShown={!!blog}
          iconColor={theme.colors.red}
          marginTop={25}
        />
      </DialogBox>
    </Dialog>
  );
};
