import { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Pane, Paragraph, Link } from "evergreen-ui";
import { CardSection } from "./CardSection";
import { AuthHeading, AuthLogo, AuthButton } from "components/Base";
import { errorHandler, windowRedirect } from "utils";

export const Checkout = ({ clientSecret }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  // submit card details
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // soft validation
      if (!stripe || !elements) return;

      //loading
      setLoading(true);

      // api request (to stripe server)
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      // hadnle error
      if (result.error) throw result.error;

      // delay to allow webhook to fire
      setTimeout(() => {
        windowRedirect("dashboard");
      }, 4000);
    } catch (error) {
      setLoading(false);
      errorHandler("We were unable to process the provided card.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Pane
        width={400}
        minHeight={600}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        paddingY={40}
        paddingX={20}
        border="solid whitesmoke 1px"
        background="white"
        borderRadius={5}
      >
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={22}
        >
          <AuthHeading title="Billing Account" />
          <AuthLogo />
          <Pane>
            <Paragraph textAlign="center">
              {`An active billing account is required for
        Premium ${process.env.REACT_APP_BRAND} accounts. `}
            </Paragraph>
          </Pane>
          <Pane display="flex" flexDirection="column" gap={8} width="100%">
            <CardSection />
            <AuthButton
              label="Add Card"
              loading={loading}
              disabled={!stripe || !clientSecret || loading}
            />
          </Pane>
        </Pane>
        <Link fontSize={10} href="https://stripe.com" target="_blank">
          Powered by Stripe
        </Link>
      </Pane>
    </form>
  );
};
