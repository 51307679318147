import { useContext, useState } from "react";
import { UserContext } from "context";
import { ProcessingLoader } from "components/Base";
import { TransactionView } from "components/Partials";
import { PdfUpload, SignUpDialog } from "./components";

export const Freemium = () => {
  const { user } = useContext(UserContext);
  const [fetching, setFetching] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  if (fetching) return <ProcessingLoader msg="Performing Assessment" />;
  return (
    <>
      {!user ? (
        <PdfUpload setFetching={setFetching} setShowSignUp={setShowSignUp} />
      ) : (
        <TransactionView
          showSummary={showSummary}
          setShowSummary={setShowSummary}
        />
      )}
      <SignUpDialog showSignUp={showSignUp} setShowSignUp={setShowSignUp} />
    </>
  );
};
