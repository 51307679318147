import { Pane, Heading } from "evergreen-ui";
import { theme } from "theme";

export const Hook = () => {
  return (
    <Pane
      width={theme.breakpoints.rootWidth}
      maxWidth={theme.breakpoints.maxWidth}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={150}
      paddingBottom={30}
    >
      <Heading color={theme.colors.white} fontSize={50} lineHeight={1.6}>
        Introducing our environmental assessment for your personal finances
      </Heading>
    </Pane>
  );
};
