import { useEffect, useState } from "react";
import { Pane, CaretUpIcon } from "evergreen-ui";
import { theme } from "theme";
import { scrollToTop } from "utils";

export const ToTopFab = () => {
  const [showFab, setShowFab] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.scrollY > 500 && !showFab) {
        setShowFab(true);
      } else if (window.scrollY <= 500 && showFab) {
        setShowFab(false);
      }
    };

    window.addEventListener("scroll", handleResize);

    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, [showFab]);

  return showFab ? (
    <Pane
      cursor="pointer"
      zIndex={100}
      elevation={4}
      position="fixed"
      bottom={25}
      right={25}
      width={60}
      height={60}
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={5}
      background={theme.colors.primary}
      borderRadius={50}
      onClick={() => scrollToTop()}
    >
      <CaretUpIcon size={35} color={theme.colors.black} />
    </Pane>
  ) : null;
};
