import { Heading, Pane, Text } from "evergreen-ui";
import { useResponsive } from "hooks";
import { theme } from "theme";

export const Example = () => {
  const { responsive } = useResponsive();

  const Section = ({ title, text, image, flexDirection }) => {
    return (
      <Pane
        width="100%"
        display="flex"
        flexDirection={flexDirection}
        justifyContent="space-between"
        alignItems="center"
        gap={25}
      >
        <Pane
          width={350}
          display="flex"
          flexDirection="column"
          gap={20}
          alignItems="center"
        >
          <Heading
            color={theme.colors.primary}
            fontSize={25}
            textAlign="center"
          >
            {title}
          </Heading>
          <Text color="white" textAlign="center" lineHeight={2.5}>
            {text}
          </Text>
        </Pane>
        <img
          alt={`${process.env.REACT_APP_BRAND}`}
          src={image}
          width={550}
          maxWidth="95%"
          height={350}
          style={{
            maxWidth: "95%",
            border: `solid ${theme.colors.yellow} 1px`,
            borderRadius: 5,
          }}
        />
      </Pane>
    );
  };

  return (
    <Pane
      width={1000}
      maxWidth="95%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={150}
      paddingY={150}
    >
      <Section
        title="Checking In"
        text={`Stay up-to-date with your daily progress`}
        image="./assets/degrowth_person1.jpeg"
        flexDirection={responsive.width > 1000 ? "row" : "column"}
      />

      <Section
        title="Purchase Power"
        text={`Gain insights into your transaction history`}
        image="./assets/degrowth_person2.jpeg"
        flexDirection={responsive.width > 1000 ? "row-reverse" : "column"}
      />
    </Pane>
  );
};
