import { Pane } from "evergreen-ui";

export const DialogBox = ({ blog, children }) => {
  return (
    <Pane
      className={blog ? "slow-fade" : "none"}
      display="flex"
      flexDirection="column"
      gap={20}
      paddingBottom={30}
    >
      {children}
    </Pane>
  );
};
