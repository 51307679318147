import axios from "axios";
import { PrimaryInput, AuthHeading} from "components/Base";
import { Button, Dialog, Pane, Text, Link, Alert } from "evergreen-ui";
import { useState } from "react";
import { errorHandler, successHandler } from "utils";

export const SignUpDialog = ({ showSignUp, setShowSignUp }) => {
  const [input, setInput] = useState({ email: "", code: "" });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // submit email
  const onSubmit = async () => {
    try {
      setError(false);
      if (!input.email.includes("@") || !input.email.includes(".")) {
        errorHandler("Invalid email format");
        return setError(true);
      }

      setLoading(true);

      const { data } = await axios.post("/api/users/create-free", input);
      setLoading(false);
      if (!data) throw new Error();
      setDisabled(true);
    } catch (error) {
      setLoading(false);
      errorHandler();
    }
  };

  // confirm email
  const onConfirm = async () => {
    try {
      setError(false);
      if (!input.code) {
        return errorHandler("Invalid code entered");
      }

      setLoading(true);

      const { data } = await axios.post("/api/users/confirm-free", input);
      setLoading(false);
      if (!data) throw new Error();
      setShowSignUp(false);
      successHandler("Thank You");
    } catch (error) {
      setLoading(false);
      errorHandler();
    }
  };

  return (
    <Dialog
      title=""
      isShown={showSignUp}
      onCloseComplete={() => setShowSignUp(false)}
      hasFooter={false}
      shouldCloseOnEscapePress={false}
      shouldCloseOnOverlayClick={false}
      containerProps={{ width: 450 }}
    >
      <Pane
        width={300}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={15}
        margin="auto"
        marginTop={-8}
        paddingBottom={80}
      >
        {!disabled ? (
            <>
            <AuthHeading title="I am not a robot" />
            <Text marginTop={-10}>Please enter a valid email address</Text>
            <PrimaryInput
              //   label="Email"
              placeholder="email"
              disabled={disabled || loading}
              error={error}
              value={input.email}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </>
        ) : (
          <>
            <Alert title={input.email} intent="success">
              <br />
              <Text>
                Please see your email and enter your confirmation code below.
              </Text>
            </Alert>
            <PrimaryInput
              label="Confirmation Code"
              placeholder="code"
              disabled={loading}
              value={input.code}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, code: e.target.value }))
              }
            />
          </>
        )}
        <Button
          width={300}
          padding={18}
          appearance="primary"
          intent="success"
          isLoading={loading}
          onClick={() => {
            if (!disabled) {
              onSubmit();
            } else {
              onConfirm();
            }
          }}
        >
          {!disabled ? "Next" : "Confirm"}
        </Button>
        <Text textAlign="center" fontSize={12}>
          Already have an account?{" "}
          <Link fontSize={12} href="/auth?m=login">
            Sign In
          </Link>
        </Text>
      </Pane>
    </Dialog>
  );
};
