import { InfoSignIcon, Tooltip, Position } from "evergreen-ui";
import { useResponsive } from "hooks";
import { theme } from "theme";

export const Infotip = ({ tip }) => {
  const { colors } = theme;
  const { responsive } = useResponsive();

  return (
    <Tooltip position={Position.TOP} content={tip}>
      <InfoSignIcon
        cursor="pointer"
        color={colors.primary}
        size={responsive.width < responsive.mobile ? 16 : 14}
      />
    </Tooltip>
  );
};
