import { Pane } from "evergreen-ui";
import { QR, IOS, Android } from "./components";
import { getOS } from "utils";

export const Pwa = () => {
  const os = getOS();
  return (
    <Pane display="flex" flexDirection="column" gap={10} marginBottom={40}>
      {!os ? <QR /> : null}
      {os === "iOS" ? <IOS /> : null}
      {os === "Android" ? <Android /> : null}
    </Pane>
  );
};
