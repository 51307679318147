import { useEffect } from "react";
import {
  BackgroundImage,
  LandingBox,
  Hook,
  CtaButton,
  Features,
  Example,
} from "./components";
import { ContentView } from "components/Partials";
import { errorHandler, onParseUrl } from "utils";

export const Landing = () => {
  // show error message if url param was sent with navigation
  useEffect(() => {
    const error = onParseUrl("e");
    if (error) {
      errorHandler();
    }
  }, []);

  return (
    <LandingBox>
      <BackgroundImage />
      <Hook />
      <CtaButton />
      <Features />
      <Example />
      <ContentView />
    </LandingBox>
  );
};
