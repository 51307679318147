import {
  Heading,
  Pane,
  Paragraph,
  Button,
  ArrowRightIcon,
  Alert,
  Text,
} from "evergreen-ui";
import { windowRedirect } from "utils";
import { theme } from "theme";

export const Plans = () => {
  const FeatureCard = ({
    title,
    content,
    list,
    location,
    btnText,
    onClick,
  }) => {
    return (
      <Pane
        elevation={4}
        width={400}
        maxWidth="95%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={20}
        background="white"
        padding={30}
        borderRadius={5}
        border="solid lightgrey 1px"
      >
        <Heading fontSize={20}>{title}</Heading>

        <Paragraph textAlign="center">{content}</Paragraph>
        <Pane width="100%">
          <Pane display="flex" flexDirection="column" gap={15}>
            {list.map((item, index) => (
              <Alert key={index} intent={item.intent} title={item.text} padding={8}>
                <Text fontSize={11}>{item.info}</Text>
              </Alert>
            ))}
          </Pane>
        </Pane>

        <Text fontSize={13}>{location}</Text>
        <Button
          appearance="primary"
          intent="success"
          iconAfter={ArrowRightIcon}
          onClick={onClick}
          fontSize={14}
          marginTop={0}
          padding={18}
        >
          {btnText}
        </Button>
      </Pane>
    );
  };

  return (
    <Pane
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      paddingTop={120}
      paddingBottom={300}
      background={theme.colors.white}
    >
      <Pane className="feature-box">
        <FeatureCard
          title="Freemium"
          content="Our free plan allows you to manual upload a PDF of your transaction history"
          list={[
            { intent: "success", text: "Free of charge", info: "" },
            { intent: "warning", text: "Manual processing", info: "" },
            { intent: "warning", text: "Slower assessment time", info: "" },
            {
              intent: "warning",
              text: "Lower accuracy assessments",
              info: "* Due to inconsistant category data.",
            },
            {
              intent: "warning",
              text: "No purchase insight and alternatives",
              info: "",
            },
          ]}
          location="Available around the world"
          btnText="Freemium"
          onClick={() => windowRedirect("dashboard-free")}
        />
        <FeatureCard
          title="Premium"
          content="Our paid plan allows you to seemlessly integrate your transaction history"
          list={[
            {
              intent: "warning",
              text: `$${process.env.REACT_APP_PRICE} USD per month`,
              info: "",
            },
            { intent: "success", text: "Automatic processing", info: "" },
            { intent: "success", text: "Faster assessment time", info: "" },
            {
              intent: "success",
              text: "Highest accuracy assessments",
              info: "* Due to consistant category data.",
            },
            {
              intent: "success",
              text: "Purchase insight and alternatives",
              info: "",
            },
          ]}
          location="Available in the USA and Canada"
          btnText="Premium"
          onClick={() => windowRedirect("auth")}
        />
      </Pane>
    </Pane>
  );
};
