import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToTopFab, PwaFab } from "./Base";
import { Navbar, Footer } from "./Layouts";
import {
  Landing,
  Plans,
  Auth,
  Billing,
  Freemium,
  Dashboard,
  Unsubscribe,
  Logo,
  Terms,
} from "./Views";
import { isRoute } from "utils";

export const AppRoutes = () => {
  isRoute();
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
      <Routes>
        <Route path="/plans" element={<Plans />} />
      </Routes>
      <Routes>
        <Route path="/auth" element={<Auth />} />
      </Routes>
      <Routes>
        <Route path="/billing" element={<Billing />} />
      </Routes>
      <Routes>
        <Route path="/dashboard-free" element={<Freemium />} />
      </Routes>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <Routes>
        <Route path="/unsubscribe" element={<Unsubscribe />} />
      </Routes>
      <Routes>
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Routes>
        <Route path="/logo" element={<Logo />} />
      </Routes>
      <PwaFab />
      <ToTopFab />
      <Footer />
    </Router>
  );
};
