import { useCallback, useEffect, useState } from "react";
import { Dialog, Pane, Paragraph, Link, Radio } from "evergreen-ui";
import { PlaidLink } from "./PlaidLink";
import { generateToken } from "./utils/generateToken";
import { errorHandler } from "utils";

export const PlaidDialog = ({
  linkToken,
  setLinkToken,
  showPlaid,
  setShowPlaid,
}) => {
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);

  const onGenerateToken = useCallback(async () => {
    try {
      const data = await generateToken();

      if (!data) throw new Error();

      setLinkToken(data);
      setShowPlaid(true);
    } catch (error) {
      setLinkToken(null);
      errorHandler();
    }
  }, [setLinkToken, setShowPlaid]);

  useEffect(() => {
    onGenerateToken();
  }, [onGenerateToken]);

  return (
    <Dialog
      title={`Privacy Policy`}
      isShown={showPlaid}
      onCloseComplete={() => {
        setShowPlaid(false);
        setAgree(false);
      }}
      hasFooter={false}
    >
      <Pane
        display="flex"
        flexDirection="column"
        gap={10}
        marginTop={-10}
        paddingBottom={40}
      >
        <Paragraph lineHeight={2}>
          <b>Degrowth</b> uses{" "}
          <Link href="https://plaid.com/why-plaid/" target="_blank">
            Plaid's
          </Link>{" "}
          financial services to securly connect to your financial institution.
          Plaid is an industry leader in online financial security and is used
          by trusted apps such as{" "}
          <Link href="https://www.acorns.com/" target="_blank">
            Acorns{" "}
          </Link>
          and{" "}
          <Link href="https://wise.com/" target="_blank">
            Wise.
          </Link>{" "}
        </Paragraph>
        <Paragraph lineHeight={2}>
          We <b>DO NOT</b> share or sell your personal data with any third-party
          vendors.
        </Paragraph>
        <Paragraph lineHeight={2}>
          We <b>DO NOT</b> handle or store sensitive data.
        </Paragraph>
        <Paragraph lineHeight={2}>
          We <b>DO NOT</b> track you.
        </Paragraph>
        <Pane borderTop="solid lightgrey 2px">
          <Radio
            checked={agree}
            size={16}
            name="group"
            label="I agree to Degrowth's Privacy Policy."
            onChange={() => setAgree(!agree)}
          />
        </Pane>
        <PlaidLink
          agree={agree}
          linkToken={linkToken}
          loading={loading}
          setLoading={setLoading}
        />
      </Pane>
    </Dialog>
  );
};
