import { useEffect, useState } from "react";
import { Pane, MobilePhoneIcon, Dialog } from "evergreen-ui";
import { Pwa } from "../Dialogs";
import { isPwa, onParseUrl } from "utils";
import { theme } from "theme";

export const PwaFab = () => {
  const [showPwa, setShowPwa] = useState(false);

  useEffect(() => {
  
    if(onParseUrl('pwa')){
      setShowPwa(true)
    }
  },[])

  return !isPwa ? (
    <>
      <Pane
        cursor="pointer"
        zIndex={100}
        elevation={4}
        position="fixed"
        bottom={25}
        right={25}
        width={60}
        height={60}
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={5}
        background={theme.colors.primary}
        borderRadius={50}
        onClick={() => setShowPwa(true)}
      >
        <MobilePhoneIcon size={35} color={theme.colors.black} />
      </Pane>
      <Dialog
        title=""
        isShown={showPwa}
        onCloseComplete={() => setShowPwa(false)}
        hasFooter={false}
      >
        <Pwa />
      </Dialog>
    </>
  ) : null;
};
