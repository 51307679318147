import { useContext } from "react";
import { UserContext } from "context";
import {
  Pane,
  Link,
  Popover,
  Button,
  Position,
  ArrowDownIcon,
} from "evergreen-ui";

export const AlternativeSelect = ({ blog, category }) => {
  const { user } = useContext(UserContext);
  const { alternatives } = user;
  const current = alternatives[category];
  
  return blog && current ? (
    <>
      <Popover
        position={Position.BOTTOM}
        content={
          <Pane
            width="fit-content"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            gap={20}
            padding={20}
          >
            {current.map((alt) => (
              <Link
                textDecoration="underline"
                href={alt.url}
                target="_blank"
              >{`${alt.name}`}</Link>
            ))}
          </Pane>
        }
      >
        <Button
          className={blog ? "slow-fade" : "none"}
          appearance="primary"
          iconAfter={ArrowDownIcon}
        >
          Alternative Options
        </Button>
      </Popover>
    </>
  ) : null;
};
