import { useEffect, useState } from "react";
import {
  Pane,
  IconButton,
  InfoSignIcon,
  Heading,
  Position,
  Tooltip,
} from "evergreen-ui";
import { HeaderBox, SummaryDialog } from "./components";

export const TransactionListHeader = ({
  toggleList,
  showSummary,
  setShowSummary,
}) => {
  const [showPop, setShowPop] = useState(true);

  useEffect(() => {
    const handleClickOutside = () => {
      setShowPop(false);
    };

    const handleScroll = () => {
      if (showPop) {
        setShowPop(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <HeaderBox>
        <Pane width={200} display="flex" alignItems="center" gap={10}>
          <Tooltip
            position={Position.TOP}
            isShown={showPop}
            content="Assessment Summary"
          >
            <IconButton
              width={40}
              height={40}
              icon={<InfoSignIcon color="#3366FF" />}
              onClick={() => setShowSummary(true)}
            />
          </Tooltip>
          <Heading>{!toggleList ? "Transaction" : "Category"}</Heading>
        </Pane>
        <Heading width={50} textAlign="center">
          Amt.
        </Heading>
        <Heading width={50}>Score</Heading>
      </HeaderBox>
      <SummaryDialog
        setShowPop={setShowPop}
        showSummary={showSummary}
        setShowSummary={setShowSummary}
      />
    </>
  );
};
