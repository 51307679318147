import { Heading } from "evergreen-ui";
import { theme } from "theme";

export  const Title = ({ children }) => {
    return (
      <Heading
        marginTop={20}
        marginBottom={10}
        fontSize={20}
        lineHeight={1.5}
        color={theme.colors.primary}
      >
        {children}
      </Heading>
    );
  };