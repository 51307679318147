import { Pane } from "evergreen-ui";

export const BackgroundImage = () => {
  return (
    <Pane
      zIndex={-1}
      position="absolute"
      top={60}
      left={0}
      width="100%"
      height="85vh"
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      opacity={0.3}
      background={`url('assets/degrowth_background_pollution.jpeg') center/cover no-repeat`}
    />
  );
};
