import { Pane } from "evergreen-ui";

export const TransactionsBox = ({ children }) => {
  return (
    <Pane
      width={1000}
      maxWidth='95%'
      minHeight='100vh'
      display="flex"
      flexDirection="column"
      gap={10}
      paddingTop={60}
      paddingBottom={150}
    >
      {children}
    </Pane>
  );
};
