import { Pane, Heading, Text, Link } from "evergreen-ui";
import { theme } from "theme";

export const Spotlight = () => {
  return (
    <Pane
      id="spotlight"
      width="100%"
      paddingY={150}
      background={theme.colors.yellow}
    >
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={20}
      >
        <Heading fontSize={30}>Spotlight Series</Heading>
        <Text>
          Featuring:{" "}
          <Link href="https://www.youtube.com/@rosaluxnyc" target="_blank">
            rosaluxnyc
          </Link>
        </Text>
        <Pane
          width={980}
          maxWidth="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <iframe
            style={{ maxWidth: "95%" }}
            title="spotlight"
            width="980"
            height="480"
            src="https://www.youtube.com/embed/ELfaaMLmhus"
            allowFullScreen
          />
        </Pane>
        <Text>
          Help support{" "}
          <Link href="https://www.youtube.com/@rosaluxnyc" target="_blank">
            rosaluxnyc
          </Link>{" "}
          by checking out their channel
        </Text>
      </Pane>
    </Pane>
  );
};
