import { ArrowRightIcon, Button, Pane } from "evergreen-ui";
import { windowRedirect } from "utils";

export const CtaButton = () => {
  const session = localStorage.getItem("session");

  return (
    <Pane
      width={1000}
      maxWidth="95%"
      display="flex"
      flexDirection="column"
      paddingBottom={100}
    >
      <Button
        width="fit-content"
        appearance="primary"
        intent="success"
        fontSize={16}
        padding={20}
        border="solid white 1px"
        iconAfter={ArrowRightIcon}
        onClick={() => {
          if (session) return windowRedirect("dashboard");
          windowRedirect("plans");
        }}
      >
        {!session ? "Get Started" : "Dashboard"}
      </Button>
    </Pane>
  );
};
