import { Pane, Button } from "evergreen-ui";
import { constants } from "constants";
export const ToggleListButton = ({ toggleList, setToggleList }) => {

  return constants.path === "/dashboard" ? (
    <Pane display="flex" justifyContent="flex-end">
      <Button
        appearance="primary"
        width="fit-content"
        onClick={() => {
          if (constants.path === "/dashboard") {
            setToggleList(!toggleList);
          }
        }}
      >
        {toggleList ? "View by Transactions" : "View by Category"}
      </Button>
    </Pane>
  ) : null;
};
