export const onParseUrl = (input) => {
  const urlString = window.location.href;

  const url = new URL(urlString);
  const output = url.searchParams.get(`${input}`);
  const path = window.location.pathname;

  if (output) {
    window.history.replaceState({}, document.title, path + "");
    return output;
  }
  return false;
};
