export const diffInDays = (transactions) => {
  // Calculate the difference in milliseconds
  const differenceInMs =
    new Date(transactions[transactions.length - 1].date) -
    new Date(transactions[0].date);

  // Convert milliseconds to days
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const days = Math.floor(differenceInMs / millisecondsInDay);
  return Math.abs(days);
};
