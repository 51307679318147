import { Pane, GlobeIcon } from "evergreen-ui";
import { theme } from "theme";

export const LoadingContent = ({ blog }) => {
  return !blog ? (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={445}
      paddingBottom={60}
    >
      <Pane className="blink">
        <GlobeIcon size={80} color={theme.colors.primary} />
      </Pane>
    </Pane>
  ) : null;
};
