import { useState } from "react";
import axios from "axios";
import {
  PrimaryInput,
  AuthLogo,
  AuthButton,
  AuthHeading,
} from "components/Base";
import { Footer } from "./Footer";
import { errorHandler, windowRedirect } from "utils";

// ********************************************
// CREATE
// ********************************************

export const Create = ({ setMode }) => {
  const [input, setInput] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onCreate = async () => {
    try {
      //soft validation
      if (!input.email.includes("@") || !input.email.includes(".")) {
        return errorHandler("Invalid email format.");
      }

      if (input.password.length < 8) {
        return errorHandler("Passwords must be at least 8 characters.");
      }

      //loading
      setLoading(true);
      setDisabled(true);

      // api request
      const { data } = await axios.post("api/users/create", input);

      // handle errors
      if (!data) throw new Error()

      if(data === "login"){
        return windowRedirect('auth?m=login')
      }

      windowRedirect("billing");
    } catch (error) {
      setLoading(false);
      errorHandler();
    }
  };

  return (
    <>
      <AuthHeading title="Create Account" />
      <AuthLogo />

      <PrimaryInput
        autoFocus={true}
        label="Email"
        type="email"
        placeholder="email"
        disabled={disabled}
        value={input.email}
        onChange={(e) =>
          setInput((prev) => ({ ...prev, email: e.target.value }))
        }
      />

      <PrimaryInput
        label="Password"
        type="password"
        disabled={disabled}
        placeholder="password"
        value={input.password}
        onChange={(e) =>
          setInput((prev) => ({ ...prev, password: e.target.value }))
        }
      />

      <AuthButton
        label={"Create Account"}
        loading={loading}
        onClick={async () => await onCreate()}
      />
      <Footer setMode={setMode} disabled={disabled} />
    </>
  );
};
