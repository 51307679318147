import { Pane, Text } from "evergreen-ui";
import { Infotip } from "../Tooltips";

export const InputLabel = ({ labelColor, label, tip }) => {
 
  return (
    <Pane display="flex" flexDirection="row" gap={7} >
      <Text fontWeight="bold" fontSize="1rem" color={labelColor}>
        {label}
      </Text>
      {tip ? <Infotip tip={tip} /> : null}
    </Pane>
  );
};
