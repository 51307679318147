import { useContext, useEffect } from "react";
import { UserContext } from "context";
import { Pane, Heading, Dialog, Alert, Text, RefreshIcon } from "evergreen-ui";
import { constants } from "constants";
import { diffInDays, windowRedirect } from "utils";
import currency from "currency.js";

export const SummaryDialog = ({ setShowPop, showSummary, setShowSummary }) => {
  const { user } = useContext(UserContext);
  const { transactions, score } = user;
  const isPassing = score >= constants.PASSING_GRADE ? "success" : "danger";

  useEffect(() => {
    const handleClickOutside = () => {
      setShowPop(false);
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowPop]);

  return (
    <Dialog
      title={
        <Pane display="flex" alignItems="center" gap={10}>
          <Heading>Assessment Summary</Heading>{" "}
          <RefreshIcon
            cursor="pointer"
            color="grey"
            size={14}
            onClick={() => windowRedirect(constants.path.split("/")[1])}
          />
        </Pane>
      }
      isShown={showSummary}
      onCloseComplete={() => setShowSummary(false)}
      hasFooter={false}
    >
      <Pane display="flex" flexDirection="column" gap={10} paddingBottom={30}>
        <Alert title="Your Score" intent={isPassing}>
          {`${score}%`}
        </Alert>
        <Alert title="Your Transfers" intent="info">
          {`${currency(user.spend).format()}`}
        </Alert>
        <Alert title="Number of Transactions" intent="info">
          {`${transactions.length}`}
        </Alert>
        <Alert title="Number of Days" intent="info">
          {`${diffInDays(transactions)}`}
        </Alert>
        <Text
          marginTop={10}
          fontSize={12}
        >{`* Passing score is ${constants.PASSING_GRADE}% or above.`}</Text>
      </Pane>
    </Dialog>
  );
};
