import { useEffect, useState } from "react";
import axios from "axios";
import { Pane, Spinner, Alert, Link } from "evergreen-ui";
import { onParseUrl, windowRedirect } from "utils";

export const Unsubscribe = () => {
  const [unsubscribe, setUnsubscribe] = useState({
    show: false,
    intent: "success",
    text: "",
  });

  const onUnsubscribe = async () => {
    try {
      const id = onParseUrl("id");

      // if (!id) throw new Error();

      const { data } = await axios.get(`/api/users/unsubscribe/${id}`);
      if (!data) throw new Error();

      if (data === "unautherized") {
        return setUnsubscribe({
          show: true,
          intent: "danger",
          text: "You must be signed in to unsubscribe.",
        });
      }

      setUnsubscribe({
        show: true,
        intent: "success",
        text: "Your email has successfully been removed from our mailing list.",
      });
    } catch (error) {
      windowRedirect();
    }
  };

  useEffect(() => {
    onUnsubscribe();
  }, []);

  return (
    <Pane className="center-box">
      {!unsubscribe.show ? (
        <Spinner />
      ) : (
        <Link href="/auth?m=login">
          <Alert intent={unsubscribe.intent} title={unsubscribe.text} />
        </Link>
      )}
    </Pane>
  );
};
