import { windowRedirect } from "./windowRedirect";

export const openRoutes = [
  "/",
  "/plans",
  "/auth",
  "/dashboard-free",
  "/terms",
  "/logo",
];
export const authRoutes = ["/billing", "/dashboard", "/unsubscribe",];

export const isRoute = () => {
  const route = window.location.pathname;
  const routes = openRoutes.concat(authRoutes);

  if (!routes.includes(route)) {
    return windowRedirect();
  }
};
