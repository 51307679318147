import { NavBox, Logo, SignInBtn, Menu, Upgrade } from "./components";

export const Navbar = () => {
  return (
    <NavBox>
      <Logo />
      <SignInBtn />
      <Menu />
      <Upgrade />
    </NavBox>
  );
};
