import { Pane } from "evergreen-ui";
export const TransactionViewBox = ({ children }) => {
  return (
    <Pane
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={120}
    >
      {children}
    </Pane>
  );
};
