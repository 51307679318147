import { useState, useEffect, useContext, useCallback } from "react";
import { UserContext } from "context";
import axios from "axios";
import { Pane, Button, ArrowRightIcon, toaster } from "evergreen-ui";
import { windowRedirect } from "utils";
import { ProcessingLoader } from "components/Base";
import { TransactionView } from "components/Partials";
import { PlaidDialog } from "./components";

export const Dashboard = () => {
  const { user, setUser } = useContext(UserContext);
  const [fetching, setFetching] = useState(true);
  const [showSummary, setShowSummary] = useState(false);
  const [showPlaid, setShowPlaid] = useState(false);
  const [linkToken, setLinkToken] = useState("");

  const getTransactions = useCallback(async () => {
    try {
      setFetching(true);
      const { data } = await axios.get("/api/plaid/transactions");
      if (!data) throw new Error();
      setFetching(false);
      if (data === "Unautherized") {
        localStorage.removeItem("session");
        setUser(null);
        return windowRedirect("auth?m=login");
      }

      if (data === "Unverified") {
        return windowRedirect("billing");
      }

      if (data === "Connect") {
        return setShowPlaid(true);
      }

      if (data?.mode === "update_mode") {
        toaster.notify('Reconnect', {id: 'xyz', description: "Your banking institution has asked that you reconnect your account.", duration: 20})
        return setLinkToken(data.linkToken);
      }

      localStorage.setItem("session", new Date().getMilliseconds());
      return setUser(data);
    } catch (error) {
      localStorage.removeItem("session");
      setUser(null);
      windowRedirect("?e=true");
    }
  }, [setUser]);

  useEffect(() => {
    // wrapping this get in a delay to enure Plaid link has fully connected
    setTimeout(() => {
      getTransactions();
    }, 2000);
  }, [getTransactions]);

  if (fetching) return <ProcessingLoader msg="Performing Assessment" />;
  return user ? (
    <TransactionView
      showSummary={showSummary}
      setShowSummary={setShowSummary}
    />
  ) : (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <PlaidDialog
        linkToken={linkToken}
        setLinkToken={setLinkToken}
        showPlaid={showPlaid}
        setShowPlaid={setShowPlaid}
      />
      <Button
        appearance="primary"
        intent="success"
        iconAfter={ArrowRightIcon}
        onClick={() => getTransactions()}
      >
        Try Again
      </Button>
    </Pane>
  );
};
