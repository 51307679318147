import { useState } from "react";
import axios from "axios";
import { Text, toaster } from "evergreen-ui";
import {
  TransactionsBox,
  TransactionListHeader,
  ToggleListButton,
  TransactionList,
  CategoryList,
  TransactionDialog,
} from "./components";
import { errorHandler } from "utils";
import { createClient } from "pexels";
//pexel client
const client = createClient(process.env.REACT_APP_PEXEL);

export const Transactions = ({ showSummary, setShowSummary }) => {
  const [toggleList, setToggleList] = useState(false);
  const [blog, setBlog] = useState("");
  const [image, setImage] = useState("");
  const [showBlog, setShowBlog] = useState(false);
  const [selected, setSelected] = useState([]);

  const onSearch = async (category, rootCategory) => {
    try {
      if (!localStorage.getItem("session")) {
        return warningHandler();
      }

      setShowBlog(true);
      const { data } = await axios.get(`/api/plaid/search/${category}`);
      if (!data) throw new Error();

      if (data === "unautherized") {
        setShowBlog(false);
        return warningHandler();
      }

      // set img
      await getImg(`Environmentalism: ${category}`);

      // set alternatives
      setSelected(rootCategory);

      // slight delay for image to load
      setTimeout(() => {
        setBlog(data);
      }, 2000);
    } catch (error) {
      errorHandler();
      setShowBlog(false);
    }
  };

  // set article image
  const getImg = async (query) => {
    try {
      const data = await client.photos.search({ query, per_page: 50 });
      const index = Math.floor(Math.random() * data.photos.length);

      setImage({
        photographer: data.photos[index].photographer,
        photographer_url: data.photos[index].photographer_url,
        source: data.photos[index].src.landscape,
      });
      return;
    } catch (error) {
      errorHandler();
      setShowBlog(false);
    }
  };

  const warningHandler = () => {
    return toaster.warning("Upgrade Required", {
      id: "xyz",
      description: "A Premium account is required for purchase insights.",
      duration: 12,
    });
  };

  return (
    <>
      <TransactionsBox>
        <ToggleListButton
          toggleList={toggleList}
          setToggleList={setToggleList}
        />
        <TransactionListHeader
          toggleList={toggleList}
          showSummary={showSummary}
          setShowSummary={setShowSummary}
        />

        {toggleList ? (
          <CategoryList onSearch={onSearch} />
        ) : (
          <TransactionList onSearch={onSearch} />
        )}

        <Text color="lightgrey">
          * We regularly adjust our assesment algorithm to apply incremental
          improvements.
        </Text>
      </TransactionsBox>

      <TransactionDialog
        selected={selected}
        image={image}
        setImage={setImage}
        blog={blog}
        setBlog={setBlog}
        showBlog={showBlog}
        setShowBlog={setShowBlog}
      />
    </>
  );
};
