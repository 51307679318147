export const ImageInput = ({ id, children, onChange }) => {
  const style = { display: "none" };
  return (
    <label htmlFor={id}>
      {children}
      <input
        id={id}
        style={style}
        type="file"
        accept=".pdf"
        onChange={onChange}
      />
    </label>
  );
};
