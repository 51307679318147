import { Pane } from "evergreen-ui";

export const Image = ({ src }) => {
  return (
    <Pane marginY={15}>
      <img
        alt={`${process.env.REACT_APP_BRAND}`}
        src={src}
        width="100%"
        style={{ cursor: "pointer" }}
      />
    </Pane>
  );
};
