import { useContext, useState } from "react";
import { UserContext } from "context";
import {
  Pane,
  Text,
  IconButton,
  GlobeIcon as InfoSignIcon,
} from "evergreen-ui";
import currency from "currency.js";
import { theme } from "theme";
import { constants } from "constants";
const { PASSING_GRADE } = constants;

export const TransactionList = ({ onSearch }) => {
  const { user } = useContext(UserContext);
  const { transactions } = user;
  const [hover, setHover] = useState("");

  return transactions.map((item) => {
    const score = item.score;
    const color =
      score >= PASSING_GRADE ? theme.colors.primary : theme.colors.red;

    // pull out var for readability
    const category = item?.personalFinanceCategory?.replaceAll("_", " ") || "";

    return (
      <>
        <Pane
          key={item.id}
          cursor="pointer"
          height={80}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={10}
          background={hover === item.id ? "whitesmoke" : "white"}
          borderLeft={`solid ${color} 5px`}
          borderRadius={5}
          onMouseEnter={() => setHover(item.id)}
          onMouseLeave={() => setHover("")}
          onClick={() => onSearch(category, item.primaryCategory)}
        >
          <Pane width={200} display="flex" alignItems="center" gap={10}>
            <IconButton
              width={40}
              height={40}
              icon={<InfoSignIcon color={color} />}
            />

            <Pane display="flex" flexDirection="column" overflow="hidden">
              <Text
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontWeight="bold"
              >
                {item.merchant || category}
              </Text>

              <Text color="grey" fontSize={10}>
                {item.date}
              </Text>
            </Pane>
          </Pane>

          <Text width={50}>{currency(item.amount).format()}</Text>
          <Text width={50} color={color} fontWeight="bold" textAlign="center">
            {score}%
          </Text>
        </Pane>
      </>
    );
  });
};
