import { GlobeIcon, Pane } from "evergreen-ui";
import { theme } from "theme";

export const Logo = () => {
  return (
    <Pane className="center-box">
      <GlobeIcon size={350} color={theme.colors.primary} />
    </Pane>
  );
};
