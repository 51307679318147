import { ContentView } from "../ContentView";
import { TransactionViewBox, Transactions, Chart } from "./components";

export const TransactionView = ({ showSummary, setShowSummary }) => {
  return (
    <TransactionViewBox>
      <Chart setShowSummary={setShowSummary} />
      <Transactions showSummary={showSummary} setShowSummary={setShowSummary} />
      <ContentView/>
    </TransactionViewBox>
  );
};
