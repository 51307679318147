import { constants } from "constants";
import { Heading } from "evergreen-ui";
import { theme } from "theme";
import { windowRedirect } from "utils";

export const SignInBtn = () => {
  return constants.path === "/" ||constants.path === "/plans" ? (
    <Heading
      cursor="pointer"
      color={theme.colors.primary}
      onClick={() => {
        const session = localStorage.getItem("session");
        if (session) {
          // auto login if session exist
          windowRedirect("dashboard");
        } else {
          windowRedirect("auth?m=login");
        }
      }}
    >
      Sign In →
    </Heading>
  ) : null;
};
