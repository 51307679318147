import { useEffect } from "react";
import { AppBox } from "./AppBox";
import { AppRoutes } from "./AppRoutes";
import { UserProvider } from "context";
import { useGoogleAnalytics } from "hooks";
import { scrollToTop } from "utils";

export const App = () => {
   useGoogleAnalytics();
    
  useEffect(() => {
    scrollToTop()
  },[])

  return (
    <UserProvider>
      <AppBox>
        <AppRoutes />
      </AppBox>
    </UserProvider>
  );
};
