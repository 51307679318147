import {
  FooterCard,
  ContactUs,
  TermsLink,
  Copywrite,
} from "./components";
import { SocialMedia } from "components/Base";

export const Footer = () => {
  return (
    <>
      <FooterCard>
        <SocialMedia/>
        <Copywrite />
        <TermsLink />
        <ContactUs />
      </FooterCard>
    </>
  );
};
