import { useContext } from "react";
import axios from "axios";
import { UserContext } from "context";
import { Pane, Text, Avatar, Button, Popover, Heading } from "evergreen-ui";
import { windowRedirect } from "utils";
import { constants } from "constants";

export const Menu = () => {
  const { user } = useContext(UserContext);

  const logout = async () => {
    try {
      axios.get("/api/users/logout");
      localStorage.removeItem("session");
      localStorage.removeItem("freemium");
      windowRedirect();
    } catch (error) {
      localStorage.removeItem("session");
      localStorage.removeItem("freemium");
      windowRedirect();
    }
  };

  return user && constants.path === "/dashboard" ? (
    <Popover
      content={
        <Pane
          display="flex"
          flexDirection="column"
          paddingX={12}
          paddingTop={6}
          paddingBottom={14}
        >
          <Pane
            display="flex"
            flexDirection="column"
            marginTop={5}
            marginBottom={10}
            gap={2}
          >
            <Heading>Signed in as:</Heading>
            <Text>{user.email}</Text>
          </Pane>
          <Button appearance="primary" intent="danger" onClick={() => logout()}>
            logout
          </Button>
        </Pane>
      }
    >
      <Avatar
        cursor="pointer"
        name={user.email || process.env.REACT_APP_BRAND}
        color="green"
        size={33}
      />
    </Popover>
  ) : null;
};
