import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Checkout } from "./components";
import { errorHandler, windowRedirect } from "utils";
import { Pane, Spinner } from "evergreen-ui";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const Billing = () => {
  const [clientSecret, setClientSecret] = useState("");

  const createClientSecret = useCallback(async () => {
    try {
      // api request
      const { data } = await axios.get("api/stripe/create-subscription");

      // handle error

      if (!data || data?.error) throw new Error();

      setClientSecret(data?.secret);
    } catch (error) {
      errorHandler();
      return setTimeout(() => {
        windowRedirect();
      }, 3000);
    }
  }, [setClientSecret]);

  useEffect(() => {
    createClientSecret();
  }, [createClientSecret]);

  return (
    <Pane
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop={100}
      paddingBottom={200}
    >
      <Elements stripe={stripePromise}>
        {clientSecret ? <Checkout clientSecret={clientSecret} /> : <Spinner />}
      </Elements>
    </Pane>
  );
};
