import { Button } from "evergreen-ui";
import { constants } from "constants";
import { windowRedirect } from "utils";

export const Upgrade = () => {

  return constants.path === "/dashboard-free" ? (
    <Button appearance="primary"  onClick={() => windowRedirect('plans')}>Upgrade Plan</Button>
  ) : null;
};
