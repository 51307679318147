import { Pane, GlobeIcon as LogoIcon } from "evergreen-ui";
import { EllipsisHeading } from "../Headings";
import { theme } from "theme";

export const Logo = ({ textColor, fontSize, ...rest }) => {

  return (
    <Pane display="flex" alignItems="center" gap={8} {...rest}>
      <LogoIcon size={20} color={theme.colors.primary} />
      <EllipsisHeading color="white">
        {process.env.REACT_APP_BRAND}
      </EllipsisHeading>
    </Pane>
  );
};
