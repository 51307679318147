import { Pane, Link } from "evergreen-ui";
import { SmallText } from "components/Base";
import { theme } from "theme";
const { colors } = theme;

export const Terms = () => {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <SmallText marginTop={10} textAlign="center">
        By continuing you agree to the {process.env.REACT_APP_BRAND}{" "}
      </SmallText>
      <Link cursor='pointer' href='/terms'>
        <SmallText color={colors.blue}>
          Terms of Use and Privacy Policy.
        </SmallText>
      </Link>
    </Pane>
  );
};
